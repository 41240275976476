import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import 'moment/locale/id';
import Events from '../Events';
import ib_1 from '../../assets/images/banner/ib_1.jpg';
import ib_2 from '../../assets/images/banner/ib_2.jpg';

class Section extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            height:window.innerHeight,
            listSchedulle: [],
        }
    }
    
    render() {
        return (
            <React.Fragment>
                <section className="home bg-home" id="home">
                    <Row className='justify-content-center'>
                        <Col lg='6'>
                            <img className='img-fluid' src={ib_1} />
                        </Col>
                        <Col lg='6'>
                            <img className='img-fluid' src={ib_2} />
                        </Col>
                    </Row>
                </section>
                <Events />
            </React.Fragment>
        );
    }
}

export default Section;