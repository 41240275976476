import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import API_URL from '../../Api'
import Moment from 'moment';
import { Link } from "react-router-dom";
import no_image from "../../assets/images/no-image.png";

class Events extends Component {
    state = {
        allEvents: []
    }

    componentDidMount() {
        this.getAllActivities()
    }

    getAllActivities(){
        API_URL.baseUrl.get('event/jenis-event-date/2')
        .then(response => response.data)
        .then((data) => {
            this.setState({allEvents:data.data});
        });
    }

    render() {
        return (
            <section className='section bg-detail' id='register'>
                <Container>
                    <Row className='justify-content-center'>
                        {
                            this.state.allEvents.map(dataJadwal =>  {
                                return (
                                    <Col lg='4' md='6' sm='3' xs='12' className='justify-content-center mb-3 mt-3'>
                                        <div className="title-box">
                                            <img alt='' style={{maxHeight: '60%', padding: '12px', borderRadius: '20px', width: '100%'}} src={dataJadwal.image != null ? dataJadwal.image : no_image} />
                                            <p className='demo-1'>{dataJadwal.name}</p>
                                            <h4 className='event-label'>{Moment(dataJadwal.start_date).format('DD MMMM YYYY')}</h4>
                                            {
                                                dataJadwal.status_full == 0 ?
                                                <Link to={`${process.env.PUBLIC_URL}/detail/`+dataJadwal.slug} style={{marginLeft: '130px'}}><button className="btn-custom mt-3">Daftar</button></Link>
                                                :
                                                <Link><button className="btn-cancel mt-3" style={{ marginLeft: '90px' }}>Pendaftaran Penuh</button></Link>
                                            }
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </section>
        );
    }
}

export default Events;