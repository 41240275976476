import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardText } from "reactstrap";
import { useSearchParams } from 'react-router-dom';
import API_URL from '../../Api'
import moment from 'moment';
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faUser, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import no_image from "../../assets/images/no-image.png";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Select from 'react-select'

class DetailEvent extends Component {
    state = {
        code_act: this.props.match.params.code,
        detail_event: [],
        lead_source: [],
        city: [],
        value:'',
        provinsi: [],
        jenjang: [],
        sekolah: [],
        nama: '',
        email: '',
        no_wa: '',
        kodekabkota: 0,
        id_provinsi: '',
        code_jenjang: '',
        selected_school: '',
        kode_leadsource: '',
        tgl_datang: '',
        btnDisable: false,
        isMember: false,
    }

    componentDidMount() {
        this.getEventDetail();
        this.getLeadsSource();
        this.getProvinsi();
        this.getJenjang()
        this.getSekolah()
        this.getDefaultCodeLeadsSource();
    }

    getEventDetail() {
        API_URL.baseUrl.get('event/event-by-slug/' + this.state.code_act)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    detail_event: data.data,
                    city: data.data.event_lokasi,
                    kodekabkota: data.data.event_lokasi.length == 1 ? data.data.event_lokasi[0].kabupaten.id_kabkota : 0
                });
            });
    }

    getLeadsSource() {
        API_URL.baseUrl.get('master/lead-source')
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    lead_source: data.data
                });
            });
    }

    getProvinsi() {
        API_URL.baseUrl.get('master/provinces')
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    provinsi: data.data
                });
            });
    }

    getJenjang() {
        API_URL.baseUrl.get('master/jenjang')
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    jenjang: data.data
                });
            });
    }

    getSekolah(){
        if(this.state.id_provinsi == '' || this.state.code_jenjang == '')
            return;
        API_URL.baseUrl.get('sekolah-indonesia?province_id='+this.state.id_provinsi+'&jenjang='+this.state.code_jenjang)
        .then(response => response.data)
        .then((data) => {
            const formattedOptions = data.data.map((item) => ({
                value: item.kode_sekolah, // Gunakan "kode_sekolah" sebagai value
                label: item.nama_sekolah  // Gunakan "nama_sekolah" sebagai label
              }));
            this.setState({
                sekolah: formattedOptions
            });
        });
    }

    getDefaultCodeLeadsSource() {
        const searchParams = new URLSearchParams(this.props.location.search);
        const source = searchParams.get('source');

        if (source == 'LS013') {
            this.setState({
                kode_leadsource: source
            })
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChangeSekolah (e){
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.getSekolah())
    }

    handleChangeEmail(e) {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            API_URL.baseUrl.get('members/check-member/' + this.state.email)
                .then(response => {
                    if (response.data.data != null) {
                        this.setState({
                            isMember: true
                        })
                    }
                    else {
                        this.setState({
                            isMember: false
                        })
                    }
                })
        })
    }

    
      handleSelectSekolah = (val) => {
        this.setState({ selected_school: val });
      };

    sendRow() {
        this.setState({
            btnDisable: true
        })

        let formData = new FormData()
        formData.append('id_konten', this.state.detail_event.id)
        formData.append('id_kabkota', this.state.kodekabkota)
        formData.append('code_leads', this.state.kode_leadsource)
        if (this.state.isMember === true) {
            formData.append('member', true)
        }
        formData.append('name', this.state.nama)
        formData.append('email', this.state.email)
        formData.append('sekolah', this.state.code_school)
        formData.append('phone', this.state.no_wa.replace('+', ''))
        formData.append('sekolah', this.state.selected_school.value)
        formData.append('province_id', this.state.id_provinsi)
        formData.append('code_jenjang', this.state.code_jenjang)
        formData.append('keterangan', this.state.tgl_datang)

        API_URL.eventUrl.post('register', formData)
        .then(response => {
            if(this.state.detail_event.status_payment === 1 && this.state.isMember === false){
                console.log(response)
                window.snap.pay(response.data.data,{
                    onSuccess:function(result){window.location.reload(false)},
                    onPending:function(result){window.location.reload(false)},
                    onError:function(result){console.log(result)},
                    onClose:function(result){console.log('window close')},
                })
            }
            this.setState({
                btnDisable:false,
                nama: '',
                email: '',
                no_wa: ''
            }, () => {
                Swal.fire({
                    title: 'Sukses',
                    icon: 'success',
                    type: 'success',
                    confirmButtonColor: '#3085d6',
                    text: 'Anda sudah berhasil terdaftar di event '+this.state.detail_event.name+', cek email/spam anda untuk info selanjutnya',  
                });
            })
        })
        .catch(error => {
            console.log(error)
            this.setState({
                btnDisable: false
            })
            Swal.fire({
                title: 'Error',
                icon: 'error',
                type: 'error',
                confirmButtonColor: '#3085d6',
                text: 'Terjadi kesalahan, cek kembali input anda',  
            });

        })
    }

    render() {
        const htmlString = this.state.detail_event.description;

        return (
            <React.Fragment>
                <section className="section bg-detail" id="home">
                    <Container className='mt-5'>
                        <Row>
                            <Col lg='12'>
                                <div>
                                    {
                                        this.state.detail_event.image !== null ?
                                            <img className='img-fluid mt-5' style={{ borderRadius: "30px" }} src={this.state.detail_event.image} />
                                            :
                                            <img className='img-fluid mt-5' style={{ borderRadius: "30px" }} src={no_image} />
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='12'>
                                <Card
                                    body
                                    style={{
                                        width: 'auto',
                                        height: '75%',
                                        backgroundColor: 'white',
                                        color: 'black',
                                        borderRadius: "30px"
                                    }}
                                    className='mt-4'
                                >
                                    <CardBody>
                                        <CardText>
                                            <Row className='justify-content-center'>
                                                <Col lg='4' md='4' sm='4' xs='12' className='text-center'>
                                                    <h4>{this.state.detail_event.name}</h4>
                                                </Col>
                                                <Col lg='4' md='4' sm='4' xs='12' className='text-center'>
                                                    <h4><FontAwesomeIcon icon={faCalendarDays} /> {moment(this.state.detail_event.start_date).format('DD MMMM YYYY')}</h4>
                                                </Col>
                                                <Col lg='4' md='4' sm='4' xs='12' className='text-center'>
                                                    <h4><FontAwesomeIcon icon={faUser} /> {this.state.detail_event.category_event}</h4>
                                                </Col>
                                            </Row>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-3'>
                            {
                                this.state.city.length > 0 ?
                                    this.state.city.map((kota) => (
                                        <Col lg='6' className='mb-2'>
                                            <Card style={{ borderRadius: "30px" }}>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm='1' lg='1'>
                                                            <h5><FontAwesomeIcon icon={faLocationDot} /></h5>
                                                        </Col>
                                                        <Col sm='11' lg='11'>
                                                            <h5>{kota.kabupaten.nama_kabkota}</h5>
                                                            <h5>{kota.alamat}</h5>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))
                                    :
                                    ''
                            }
                        </Row>
                        <Row>
                            <Col lg='8'>
                                <h2 className='mt-5'>Deskripsi Event</h2>
                                <div className='mb-5' dangerouslySetInnerHTML={{ __html: htmlString }}></div>
                                {
                                    this.state.detail_event.category_event == 'Online' || this.state.detail_event.category_event == 'Hybrid' ?
                                        <>
                                            <h2 className='mt-5'>Link Online Meeting</h2>
                                            <div>
                                                <h4>{this.state.detail_event.zoom_link}</h4>
                                            </div>
                                        </>
                                        :
                                        ''
                                }
                            </Col>
                            <Col lg='4' className='mt-5' style={{ backgroundColor: 'white', borderRadius: '20px' }}>
                                <h2 className='mt-4'>Register</h2>
                                <form>
                                    {
                                        (this.state.detail_event.category_event == 'Offline' || this.state.detail_event.category_event == 'Hybrid') && this.state.city > 1 ?
                                            <div className='form-group mb-4'>
                                                <select className="form-control" name="kodekabkota" onChange={(e) => this.handleChange(e)} >
                                                    <option value='0' defaultValue>--Pilih Kota--</option>
                                                    {
                                                        this.state.city.length > 0 ?
                                                            this.state.city.map((kota) => (
                                                                <option value={kota.kode_kabkota}>{kota.kabupaten.nama_kabkota}</option>
                                                            ))
                                                            :
                                                            ''
                                                    }
                                                </select>
                                            </div>
                                            :
                                            ''
                                    }
                                    {
                                        this.state.detail_event.event_date != undefined ?
                                            this.state.detail_event.event_date.length > 0 ?
                                                <div className='form-group mb-4'>
                                                    <select className="form-control" name="tgl_datang" onChange={(e) => this.handleChange(e)}>
                                                        <option value='0' defaultValue>--Pilih Tanggal--</option>
                                                        {
                                                            this.state.detail_event.event_date.map((date) => (
                                                                <option value={date.event_date}>{moment(date.event_date).format('DD MMMM YYYY')}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                :
                                                ""
                                            :
                                            ""
                                    }
                                    {
                                        this.state.kode_leadsource != 'LS013' ?
                                            <div className='form-group mb-4'>
                                                <select className="form-control" name="kode_leadsource" onChange={(e) => this.handleChange(e)} >
                                                    <option value='0' defaultValue>--Pilih Sumber Informasi--</option>
                                                    {
                                                        this.state.lead_source != null ?
                                                            this.state.lead_source.map((lead) => (
                                                                <option value={lead.code_leads_source}>{lead.source}</option>
                                                            ))
                                                            :
                                                            ''
                                                    }
                                                </select>
                                            </div>
                                            :
                                            ''
                                    }
                                    <div className='form-group mb-4'>
                                        <input type='text' placeholder="Nama Lengkap" className='form-control' name="nama" onChange={(e) => this.handleChange(e)} />
                                    </div>
                                    <div className='form-group mb-4'>
                                        <PhoneInput defaultCountry='ID' international countryCallingCodeEditable={false}
                                            placeholder="Enter phone number" value={this.state.no_wa} name="no_wa" onChange={(e) => this.setState({
                                                no_wa: e
                                            })} />
                                    </div>
                                    <div className='form-group mb-4'>
                                        <input placeholder="Email" className='form-control' name="email" onChange={(e) => this.handleChangeEmail(e)} />
                                    </div>
                                    <div className='form-group mb-4'>
                                        <select className="form-control" name="id_provinsi" value={this.state.id_provinsi} onChange={(e) => this.handleChangeSekolah(e)} >
                                            <option value='0' defaultValue>--Pilih Propinsi--</option>
                                            {
                                                this.state.provinsi.length > 0 ?
                                                    this.state.provinsi.map((province) => (
                                                        <option value={province.id}>{province.province_name}</option>
                                                    ))
                                                    :
                                                    ''
                                            }
                                        </select>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <select className="form-control" name="code_jenjang" value={this.state.code_jenjang} onChange={(e) => this.handleChangeSekolah(e)} >
                                            <option value='0' defaultValue>--Pilih Jenjang--</option>
                                            {
                                                this.state.jenjang.length > 0 ?
                                                    this.state.jenjang.map((jenjang) => (
                                                        <option value={jenjang.code_jenjang}>{jenjang.jenjang_pendidikan}</option>
                                                    ))
                                                    :
                                                    ''
                                            }
                                        </select>
                                    </div>
                                    <div className='form-group mb-4'>
                            <Select
                            placeholder="Pilih Sekolah..."
                            classNamePrefix="select"
                              isSearchable={true}
                              className="basic-single"
                              onChange={this.handleSelectSekolah}
                            value={this.state.selected_school}
                             options={this.state.sekolah}
                            />
                                    </div>
                                    <div style={{ textAlign: 'right' }} className='form-group mb-4'>
                                        <button disabled={this.state.btnDisable} className="btn-custom" type='button' onClick={() => this.sendRow()}>Register</button>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default DetailEvent;