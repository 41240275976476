import axios from 'axios'

// const url = 'http://103.41.205.87:80/api/v1/application';
// const urlEvent = 'http://103.41.205.87:80/event';
// const urlSuperStudent = 'http://103.41.205.87:80';
// const urlRegister = 'http://103.41.205.87:80/api/v1';
// const baseSuperStudent = 'http://103.41.205.87:80/storage';

const url = 'https://superstudent.niecindonesia.com/api/v1/application';
const urlEvent = 'https://superstudent.niecindonesia.com/event';
const urlSuperStudent = 'https://superstudent.niecindonesia.com';
const urlRegister = 'https://superstudent.niecindonesia.com/api/v1';
const baseSuperStudent = 'https://superstudent.niecindonesia.com/storage';


const baseUrl =  axios.create({
    baseURL: url,
    timeout: 10000,
    headers:{
        'Content-Type': 'application/json'
    }
})

const eventUrl =  axios.create({
    baseURL: urlEvent,
    timeout: 10000,
    headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ`
    }
})

const superstudent = axios.create({
    baseURL : urlSuperStudent,
    timeout: 10000,
    headers:{
        'Content-Type': 'application/json'
    }
})

const register = axios.create({
    baseURL : urlRegister,
    timeout: 10000,
    headers:{
        'Content-Type': 'application/json'
    }
})

const API_URL = {
    baseUrl,
    eventUrl,
    superstudent,
    register,
    baseSuperStudent
}

export default API_URL