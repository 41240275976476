import React, { Component } from 'react';
import { 
    Navbar,
    Nav,
    NavbarBrand,
    NavItem,
    Container
} from "reactstrap";
import { Link } from "react-router-dom";
import StickyHeader from 'react-sticky-header';
import '../../../node_modules/react-sticky-header/styles.css';
import logo from "../../assets/images/logo.png";

class NavbarPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            isOpenMenu :false,
            navItems : [
                { id: 1 , idnm : "home", navheading: "Home", to: '/' }
            ],
        };
    }
    
    render() {
        return (
            <>
                <StickyHeader
                    header = {
                        <Navbar container={false} expand="lg" fixed="top" className="navbar-custom sticky">
                            <Container>
                                <NavbarBrand className="logo text-uppercase" href="/">
                                    <img src={logo} alt="logo" height="45" />
                                </NavbarBrand>
                                <Nav navbar className="m-auto navbar-center" id="mySidenav">
                                    {this.state.navItems.map((item, key) => (
                                        <NavItem key={key}>
                                            <Link to={`${process.env.PUBLIC_URL}/`} >{item.navheading}</Link>
                                        </NavItem>
                                    ))} 
                                </Nav>
                            </Container>
                        </Navbar>
                    }
                    stickyOffset = {-100}
                />
            </>
        );
    }
}

export default NavbarPage;